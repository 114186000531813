import { VaunceAppCMTTicketOnPosType } from '@kinderlabs-pos/shared-data-type';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Typography } from '@mui/material';

interface VaunceAppTicketUserDialogProps extends XlDialogProps {
	cmtTicket: VaunceAppCMTTicketOnPosType & {
		canUseTicket: boolean;
	};
}
export const VaunceCmtTicketUserDialog = ({
	open,
	closeDialog,
	cmtTicket,
}: VaunceAppTicketUserDialogProps) => {
	return (
		<WithDialog.XlDialog
			dialogTitle={`정기권 정보`}
			open={open}
			closeDialogAction={'닫기'}
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 428 }}>
				<Typography variant='subtitle1'>{cmtTicket.ticketTitle}</Typography>
				<Typography variant='body2'>{cmtTicket.ticketOrderNum}</Typography>
				<img
					src={cmtTicket.userImageUrl}
					width={240}
					height={240}
				/>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};
