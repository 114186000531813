import { getTelephoneNumberWithDash } from '@kinderlabs-pos/shared-util';
import { GuestMemberInfoState } from '@kinderlabs-pos/state';
import { WithDialog, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { DialogContent, Stack, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { VisitListInfoSection } from 'apps/pos-main/src/modules/member-search/UserInfoResultSection';
import { GuestVisitDetailContentWrapper } from 'apps/pos-main/src/pages/GuestVisitPage/GuestVisitDetailContent';
import { Suspense } from 'react';

export const GuestVisitDetailDialog = ({
	open,
	closeDialog,
	guestVisitId,
	guestMemberId,
}: XlDialogProps & {
	guestVisitId?: number;
	guestMemberId?: number;
}) => {
	return (
		<WithDialog.XlDialog
			scroll='paper'
			open={open}
			closeDialogAction='닫기'
			closeDialog={closeDialog}>
			<DialogContent sx={{ minWidth: 720 }}>
				<Suspense>
					{guestVisitId ? (
						<GuestVisitDetailContentWrapper guestVisitId={guestVisitId} />
					) : (
						guestMemberId && <GuestMemberDetailInternal guestMemberId={guestMemberId} />
					)}
					{guestMemberId && (
						<VisitListInfoSection
							visitListInfo={{
								guestMemberId,
							}}
						/>
					)}
				</Suspense>
			</DialogContent>
		</WithDialog.XlDialog>
	);
};

const GuestMemberDetailInternal = ({ guestMemberId }: { guestMemberId: number }) => {
	const { data } = useQuery({
		...GuestMemberInfoState.keys.detailById(guestMemberId),
		keepPreviousData: false,
	});

	return (
		<Stack mb={2}>
			<Stack
				direction={'row'}
				alignItems={'center'}
				justifyContent={'between'}>
				<Typography variant='h4'>
					{data ? getTelephoneNumberWithDash(data.member.telephone) : ''}
				</Typography>
			</Stack>
		</Stack>
	);
};
