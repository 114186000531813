import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import { DdiziType, DeviceType } from '@kinderlabs-pos/shared-data-type';
import { useAtomValue } from 'jotai';
import { AdminProductInfoState } from '../Admin/AdminProductInfoState';
import { StoreInfoState } from '../StoreInfoState';
import { KioskElectronStoreState, PosElectronStoreState } from '@kinderlabs-pos/state';

export const useReceiptDdiziPrinter = ({
	storeId,
	deviceType,
}: {
	storeId: number;
	deviceType: DeviceType;
}) => {
	const { receiptPrinterList: RPList, printMode } = StoreInfoState.useCurDeviceInfoForOrder();
	const storeInfo = useAtomValue(StoreInfoState.curStoreInfo);
	const productMap = AdminProductInfoState.useMapInStoreQuery({ storeId });
	const localSettings =
		deviceType === 'POS'
			? PosElectronStoreState.usePosMainPageLocalSettings().data
			: KioskElectronStoreState.useKioskMainPageLocalSettings().data;

	return {
		print영수증띠지Async: async ({ ddizi }: { ddizi: DdiziType }) => {
			const CandidatePrinterInfos = RPList.filter(
				(p) => p.hardwareType.usageType === 'DDIZI' && p.isActive
			);

			for (const printerInfo of CandidatePrinterInfos) {
				await ReactRPIPC.invoke영수증띠지출력({
					hardwareInfo: {
						deviceType: deviceType,
						printerInfo:
							deviceType === 'POS'
								? printerInfo
								: {
										...printerInfo,
										deviceType: localSettings ? localSettings.receiptPrintingType : 'KIOSK',
								  },
						printMode,
					},
					ddiziInfo: ddizi,
					description: AdminProductInfoState.utils.getProductInfoIdFromMap({
						productId: ddizi.publishInfo.productInfoId,
						productMap,
					})?.description,
					barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
				});
			}
		},
	};
};
