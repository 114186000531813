import { MuiReactTableUtils } from '@kinderlabs-pos/frameworks/table';
import { getNewCountLabel, numberWithCommasAndWon } from '@kinderlabs-pos/shared-util';
import { ColumnDef, Row, SortingFn } from '@tanstack/react-table';

const get금액ColumnDef = <TData,>(
	columnId: string,
	forExcel = false
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: ({ getValue }) => numberWithCommasAndWon(getValue() as number),
	footer: (headerContext) =>
		forExcel
			? MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
			  })
			: numberWithCommasAndWon(
					MuiReactTableUtils.getAggregatedValue({
						headerContext,
						columnId,
					})
			  ),
	aggregationFn: 'sum',
});

const 요일Mapping: Record<string, number> = {
	월: 1,
	화: 2,
	수: 3,
	목: 4,
	금: 5,
	토: 6,
	일: 7,
};

const 요일SortingFn = <TData,>(rowA: Row<TData>, rowB: Row<TData>, columnId: string) => {
	const dayA = 요일Mapping[rowA.getValue(columnId) as string];
	const dayB = 요일Mapping[rowB.getValue(columnId) as string];

	// 내림차순 정렬을 위해 비교
	if (dayA > dayB) {
		return -1;
	}
	if (dayA < dayB) {
		return 1;
	}
	return 0;
};

const get금액반올림ColumnDef = <TData,>(
	columnId: string
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn' | 'meta'> => ({
	cell: ({ getValue }) => numberWithCommasAndWon(Math.round(getValue() as number)),
	footer: (headerContext) =>
		numberWithCommasAndWon(
			Math.round(
				MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
				})
			)
		),
	aggregationFn: 'sum',
	// NX 에서 ts declare 를 전역적으로 쉽게 할 수 있는 방법이 없네요. excel반올림 으로 검색할 것
	meta: {
		excel반올림: true,
	},
});

const get건수ColumnDef = <TData,>(
	columnId: string,
	forExcel = false
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: (info) => getNewCountLabel(info.getValue() as number),
	footer: (headerContext) =>
		forExcel
			? MuiReactTableUtils.getAggregatedValue({
					headerContext,
					columnId,
			  })
			: getNewCountLabel(
					MuiReactTableUtils.getAggregatedValue({
						headerContext,
						columnId,
					})
			  ),
	aggregationFn: 'sum',
});

const get개수세기ColumnDef = <TData,>(
	columnId: string,
	postFix?: string
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregationFn'> => ({
	cell: (info) => `${info.getValue() as number}${postFix ? ` ${postFix}` : ''}`,
	footer: (headerContext) =>
		`${MuiReactTableUtils.getAggregatedValue({
			headerContext,
			columnId,
		})}${postFix ? ` ${postFix}` : ''}`,
	aggregationFn: 'sum',
});

const get가격ColumnDef = <TData,>(): Pick<
	ColumnDef<TData>,
	'cell' | 'footer' | 'aggregatedCell' | 'aggregationFn'
> => ({
	cell: (info) => numberWithCommasAndWon(info.getValue() as number),
	footer: undefined,
	//@ts-ignore
	aggregationFn: null,
});

const get퍼센티지ColumnDef = <TData,>(
	columnId: string,
	options?: {
		forExcel?: boolean;
		point?: boolean;
	}
): Pick<ColumnDef<TData>, 'cell' | 'footer' | 'aggregatedCell' | 'aggregationFn'> => ({
	cell: ({ getValue }) =>
		options?.point
			? `${(getValue() as number).toFixed(2)}%p`
			: `${(getValue() as number).toFixed(2)}%`,
	// aggregationFn: (columnId, leafRows, childRows) => {
	// 	const { now총합, total총합 } = leafRows.reduce(
	// 		(agg, row) => {
	// 			agg.now총합 = agg.now총합 + get부분Data(row.original).now_realRevenueSum;
	// 			agg.total총합 = agg.total총합 + row.original.subtotal.now_realRevenueSum;
	// 			return agg;
	// 		},
	// 		{ now총합: 0, total총합: 0 }
	// 	);

	// 	return get퍼센트구하기(now총합, total총합);
	// },
	// footer: ({ table }) => {
	// 	const { now총합, total총합 } = table.getFilteredRowModel().rows.reduce(
	// 		(agg, row) => {
	// 			agg.now총합 = agg.now총합 + get부분Data(row.original).now_realRevenueSum;
	// 			agg.total총합 = agg.total총합 + row.original.subtotal.now_realRevenueSum;
	// 			return agg;
	// 		},
	// 		{ now총합: 0, total총합: 0 }
	// 	);

	// 	const result = get퍼센트구하기(now총합, total총합);
	// 	return forExcel ? result.toFixed(2) : `${result.toFixed(2)}%`;
	// },
});
export const ColumnDefsHelper = {
	요일SortingFn,
	get금액ColumnDef,
	get금액반올림ColumnDef,
	get건수ColumnDef,
	get가격ColumnDef,
	get개수세기ColumnDef,
	get퍼센티지ColumnDef,
};

const get퍼센트구하기 = (분자: number, 분모: number, criticalNumber?: number) => {
	return (criticalNumber ?? 분모) === 0 ? 100 : (100 * 분자) / 분모;
};
