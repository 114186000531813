import { PaymentDetailContents } from '@kinderlabs-pos/feature/pos/payments';
import {
	OrderDetailType,
	PaymentInfoType,
	PaymentMethodType,
} from '@kinderlabs-pos/shared-data-type';
import { Box, Button } from '@mui/material';
import { PointPaymentInfoToRefundType } from './hooks/useHandlePointFormik';
import { EasyPayPaymentInfoToRefundType } from './hooks/useHandleSimplePaymentRefundFormik';
import { CardPaymentInfoToRefundType } from './hooks/useHandleCardRefundFormik';
import { PaymentDetailEasyPayActions } from './PaymentDetailEasyPayActions';
import { PaymentETCDetailActions } from './PaymentETCDetailActions';
import { PaymentDetail카드Actions } from './PaymentDetailCardActions';
import { PaymentDetail현금영수증Actions } from './PaymentDetailCashReceiptActions';

export const PaymentDetailContentsWrapper = ({
	order,
	payment,
	onCompleteRefund,
	closeDialog,
	withRefunds = false,
}: {
	order: OrderDetailType;
	payment: OrderDetailType['payments'][number];
	onCompleteRefund?: (paymentInfo: PaymentInfoType) => void;
	closeDialog?: () => void;
	withRefunds?: boolean;
}) => {
	return (
		<PaymentDetailContents
			payment={payment}
			Actions={
				<Box>
					{detailActionType[payment.method] === '현금영수증' && (
						<PaymentDetail현금영수증Actions
							order={order}
							payment={payment}
							withRefunds={withRefunds}
							onCompleteRefund={onCompleteRefund}
							closeDialog={closeDialog}
						/>
					)}
					{withRefunds && detailActionType[payment.method] === '카드' && (
						<PaymentDetail카드Actions
							order={order}
							payment={payment as CardPaymentInfoToRefundType}
							onCompleteRefund={onCompleteRefund}
							closeDialog={closeDialog}
						/>
					)}
					{withRefunds && detailActionType[payment.method] === '간편결제' && (
						<PaymentDetailEasyPayActions
							order={order}
							payment={payment as EasyPayPaymentInfoToRefundType}
							onCompleteRefund={onCompleteRefund}
							closeDialog={closeDialog}
						/>
					)}
					{withRefunds && detailActionType[payment.method] === '기타' && (
						<PaymentETCDetailActions
							order={order}
							payment={payment as PointPaymentInfoToRefundType}
							onCompleteRefund={onCompleteRefund}
							closeDialog={closeDialog}
						/>
					)}
				</Box>
			}
		/>
	);
};

const detailActionType: Record<PaymentMethodType, '현금영수증' | '카드' | '간편결제' | '기타'> = {
	CARD: '카드',
	CASH: '현금영수증',
	VIRTUAL_CARD: '카드',
	TRANSFER: '현금영수증',
	ZEROPAY_MPM: '기타',
	SEOULPAY_MPM: '기타',
	CASH_IC: '카드',
	CARD_MS: '카드',
	CREDIT: '기타',
	POINT: '기타',
	GIFTCARD: '현금영수증',
	KAKAOPAY: '간편결제',
	SSGPAY: '간편결제',
	SEOULPAY: '간편결제',
	APPCARD: '간편결제',
	ALIPAY: '간편결제',
	WECHATPAY: '간편결제',
	NAVERPAY: '간편결제',
	BCPAYBOOC: '간편결제',
	ZEROPAY: '간편결제',
	PAYCO: '간편결제',
};
