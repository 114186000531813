import {
	EasyPayMethodType,
	EasyPayMethodTypeArray,
	has간편결제지원,
	is할부가능,
	PaymentMethodTypeLabel,
	PaymentRequestInfoType,
} from '@kinderlabs-pos/shared-data-type';
import { numberWithCommasAnd원 } from '@kinderlabs-pos/shared-util';
import { PaymentState, StoreInfoState } from '@kinderlabs-pos/state';
import { QRInput, SubmitButton, XlDialogProps } from '@kinderlabs-pos/ui-atoms';
import { InstallmentSelect } from '@kinderlabs-pos/ui-components';
import {
	Box,
	Button,
	DialogActions,
	DialogContent,
	Stack,
	styled,
	ToggleButton,
	ToggleButtonGroup,
	Typography,
} from '@mui/material';
import BarcodeScanGif from 'assets/barcode-scan.gif';
import { useCallback, useEffect } from 'react';
import * as Yup from 'yup';
import { PaymentDialog } from './PaymentDialog';
import { useAtomValue } from 'jotai';

import KAKAOPAYImg from 'assets/easypay/kakaopay.png';
import SSGPAYImg from 'assets/easypay/ssgpay.png';
import SEOULPAYImg from 'assets/easypay/seoulpay.png';
import APPCARDImg from 'assets/easypay/appcard.png';
import ALIPAYImg from 'assets/easypay/alipay.png';
import WECHATPAYImg from 'assets/easypay/wechatpay.png';
import NAVERPAYImg from 'assets/easypay/naverpay.png';
import BCPAYBOOCImg from 'assets/easypay/bcpaybooc.png';
import ZEROPAYImg from 'assets/easypay/zeropay.png';
import PAYCOImg from 'assets/easypay/payco.png';

export type HandleSubmitEasyPaymentArgsType = {
	method: EasyPayMethodType;
	amount: number;
	installment: number;
	customerId: string;
};
export interface IEasyPayDialogProps extends XlDialogProps {
	결제할금액: number;
	handleSubmitPayment: (paymentRequestInfo: PaymentRequestInfoType) => Promise<void>;
	storeId: number;
	posId: number;
	orderId?: string;
}
export const EasyPayDialog: React.FC<IEasyPayDialogProps> = ({
	결제할금액,
	open,
	closeDialog,
	handleSubmitPayment,
	storeId,
	posId,
	orderId,
}) => {
	const { handleSubmit, isSubmitting, isValid, values, handleChange, setValues, errors } =
		PaymentState.actions.submit.usePaymentFormik<{
			method: EasyPayMethodType | 'NOT_SELECTED';
			installment: number;
			customerId: string;
		}>({
			storeId,
			posId,
			onClose: closeDialog,
			initialValues: {
				method: 'NOT_SELECTED',
				installment: 0,
				customerId: '',
			},
			handleSubmitPayment,
			validationSchema: Yup.object().shape({
				customerId: Yup.string().required().min(1),
			}),
			getHandlePaymentArgs: (values) => ({
				method: values.method as EasyPayMethodType,
				amount: 결제할금액,
				installment: values.installment,
				customerId: values.customerId,
			}),
		});
	const isNotSelected = values.method === 'NOT_SELECTED';
	const is결제할부가능 = isNotSelected ? false : is할부가능[values.method as EasyPayMethodType];
	useEffect(() => {
		if (!is결제할부가능) setValues((prev) => ({ ...prev, installment: 0 }));
	}, [is결제할부가능]);
	const handle결제모듈강제취소 = PaymentState.actions.submit.useHandlePaymentPM강제취소();
	const handleCancelPayment = useCallback(async () => {
		await handle결제모듈강제취소();
		closeDialog && closeDialog();
	}, []);

	const { vanCompany } = useAtomValue(StoreInfoState.curPosInfo);

	const LogoImgs = [
		KAKAOPAYImg,
		SSGPAYImg,
		SEOULPAYImg,
		APPCARDImg,
		ALIPAYImg,
		WECHATPAYImg,
		NAVERPAYImg,
		BCPAYBOOCImg,
		ZEROPAYImg,
		PAYCOImg,
	];

	return (
		<PaymentDialog
			dialogTitle={'간편 결제'}
			open={open}
			handleSubmitPayment={handleSubmitPayment}
			onClose={closeDialog}
			failedPaymentRequest={values.failedPaymentRequestData}>
			<DialogContent sx={{ minWidth: 768 }}>
				<Stack
					direction={'row'}
					spacing={1}>
					<Stack
						direction={'column'}
						width={240}
						spacing={1}>
						<Stack>
							<Typography
								variant='h1'
								sx={{ flex: 1 }}>
								결제할 금액
							</Typography>
							<Typography
								variant='h1'
								sx={{ textAlign: 'right', fontSize: 20 }}>
								{`${numberWithCommasAnd원(결제할금액)}`}
							</Typography>
						</Stack>
						<Stack>
							<ToggleButtonGroup
								color={'primary'}
								orientation='vertical'
								size={'small'}
								exclusive
								value={values.method}
								onChange={(e, value) => {
									value && setValues((prev) => ({ ...prev, method: value, customerId: '' }));
								}}>
								{EasyPayMethodTypeArray.map((value, idx) => (
									<ToggleButton
										disabled={!has간편결제지원[vanCompany][value]}
										// disabled={false}
										value={value}
										key={idx}>
										<Stack
											direction={'row'}
											alignItems={'center'}
											width={1}
											justifyContent={'flex-start'}
											paddingLeft={'60px'}
											spacing={2}>
											<EasyPayLogo name={LogoImgs[idx]} />
											<Typography>{`${PaymentMethodTypeLabel[value]}`}</Typography>
										</Stack>
									</ToggleButton>
								))}
							</ToggleButtonGroup>
						</Stack>
						<Stack spacing={1}>
							<Stack>
								<Typography variant='subtitle1'>{'할부 선택'}</Typography>
								<InstallmentSelect
									disabled={!is결제할부가능 || isNotSelected}
									value={values.installment}
									onChange={(e) => setValues((prev) => ({ ...prev, installment: +e.target.value }))}
								/>
							</Stack>
						</Stack>
					</Stack>
					<Stack
						direction={'column'}
						flex={1}>
						<Box height={'100%'}>
							<Stack
								p={3}
								height={'100%'}
								alignItems={'center'}
								justifyContent={'center'}
								spacing={1}>
								<Stack
									spacing={2}
									alignItems={'center'}>
									<BarcodeScan />
									<Typography
										variant={'h5'}
										color={'secondary'}>
										바코드, QR을 읽혀주세요
									</Typography>
									<form onSubmit={handleSubmit}>
										<Stack
											spacing={1}
											direction={'row'}>
											<QRInput
												name={'customerId'}
												value={values.customerId}
												autoFocus
												onChange={handleChange('customerId')}
												disabled={isNotSelected}
											/>
											<SubmitButton
												type='submit'
												isSubmitting={isSubmitting}
												disabled={!isValid || isSubmitting || isNotSelected}
												variant={'contained'}>
												결제하기
											</SubmitButton>
										</Stack>
									</form>
								</Stack>
								<Stack>
									<Typography
										variant='subtitle1'
										color={'error'}>
										{errors.submit}
									</Typography>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</Stack>
			</DialogContent>
			<DialogActions>
				<Button
					disabled={values.status === 'SUBMITTING_SERVER'}
					variant={'outlined'}
					onClick={handleCancelPayment}>
					취소
				</Button>
			</DialogActions>
		</PaymentDialog>
	);
};

const BarcodeScan = styled(Box)(({ theme }) => ({
	backgroundImage: `url(${BarcodeScanGif})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: 'cover',
	width: '120px',
	height: '120px',
}));

const EasyPayLogo = styled(Box)(({ name }: { name: string }) => ({
	backgroundImage: `url(${name})`,
	backgroundRepeat: 'no-repeat',
	backgroundSize: '100%',
	width: '2.5rem',
	height: '2.5rem',
}));
