import {
	canCall,
	canCancel,
	GuestUserType,
	GuestVisitType,
} from '@kinderlabs-pos/shared-data-type';
import { GuestMemberInfoState, GuestVisitState, OrderState } from '@kinderlabs-pos/state';
import { Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { CallButton } from '../PosHeader/PosSideDrawer/buttons/CallButton';
import { DeleteButton } from '../PosHeader/PosSideDrawer/buttons/DeleteButton';
import { RunButton } from '../PosHeader/PosSideDrawer/buttons/RunButton';
import { useAlert } from '@kinderlabs-pos/ui-components';
import { WaitingIndicator } from '../PosHeader/WaitingIndicator';

export const GuestVisitActionButtons = ({
	guestVisit,
	withCalls = true,
	closePannel,
	withoutCancelBtn = false,
}: {
	guestVisit: GuestVisitType;
	withCalls?: boolean;
	closePannel?: () => void;
	withoutCancelBtn?: boolean;
}) => {
	const { guestMemberInfo } = useAtomValue(OrderState.value);
	const dispatchOrder = useSetAtom(OrderState.value);
	const { sunbun, memberInfo, id, users, visitCount } = guestVisit;
	const setCalled지류티켓 = useSetAtom(WaitingIndicator.called지류티켓Atom);
	const { data } = useQuery({
		...GuestMemberInfoState.keys.detailById(memberInfo?.memberId),
		refetchOnWindowFocus: true,
	});
	const appMemberInfo = data?.vaunceMemberInfo ?? null;

	const callMutation = GuestVisitState.actions.useCallMutation({
		onError: (e) => {
			//@ts-ignore
			customAlert(e?.message ?? '호출실패');
		},
	});
	const cancelMutation = GuestVisitState.actions.useCancelMutation();
	const rerunMutation = GuestVisitState.actions.useRerunMutation();

	const customAlert = useAlert();
	const handleClickCallButton = () => {
		callMutation.mutate({ guestVisitId: id });
	};

	const handleClickRunButton = () => {
		rerunMutation.mutate({ guestVisitId: id });
		if (memberInfo) {
			dispatchOrder({
				type: 'SET_GUEST_USERS_INFO',
				guestMemberInfo: {
					...memberInfo,
					sunbun,
					users: getUsersByGVInfo({ users, visitCount }),
					guestVisitId: id,
					appUserId: appMemberInfo?.userId,
				},
			});
			setCalled지류티켓(undefined);
		} else {
			setCalled지류티켓(guestVisit);
		}
		closePannel && closePannel();
	};

	const handleClickDeleteButton = () => {
		cancelMutation.mutate({ guestVisitId: id });
	};

	return (
		<Stack
			direction={'row'}
			justifyContent={'center'}
			spacing={2}>
			<RunButton
				disabled={!!guestMemberInfo}
				onClick={handleClickRunButton}
			/>
			{!withoutCancelBtn && (
				<DeleteButton
					disabled={!canCancel[guestVisit.status]}
					onClick={handleClickDeleteButton}
				/>
			)}
			{withCalls && (
				<CallButton
					disabled={!canCall[guestVisit.status] || callMutation.isLoading}
					callCount={guestVisit.calls?.length || 0}
					onClick={handleClickCallButton}
				/>
			)}
		</Stack>
	);
};

export const getUsersByGVInfo = ({
	users,
	visitCount,
}: {
	users: GuestUserType[];
	visitCount: GuestVisitType['visitCount'];
}): GuestUserType[] => {
	if (users.length > 0) return users;
	else if (visitCount)
		return [...new Array(visitCount.totalCount)].map(() => ({ guestType: 'NORMAL' }));
	else return [];
};
