import { ReactRPIPC } from '@kinderlabs-pos/ipc/react';
import {
	AvailableBarcodeModelType,
	CustomerReceiptRequestDummy,
	ExchangeCouponRequestDummy,
	FoodCustomerReceiptRequestDummy,
	FrontReceiptRequestDummy,
	KitchenReceiptRequestDummy,
	VaultcashReceiptRequestDummy,
	getDdiziDummy,
} from '@kinderlabs-pos/shared-data-type';
import { PosElectronStoreState, PrintReceiptState, StoreInfoState } from '@kinderlabs-pos/state';
import {
	Box,
	Button,
	FormControl,
	FormControlLabel,
	FormLabel,
	MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
} from '@mui/material';
import { useAtom, useAtomValue } from 'jotai';
import { SettingMenuItem } from '../common';
import { useState } from 'react';

export const ReceiptPage = () => {
	const { storeId, deviceId } = useAtomValue(StoreInfoState.curStoreAndDevice);
	const curPosInfo = useAtomValue(StoreInfoState.curPosInfo);
	const { printMode } = curPosInfo;

	const CandidateCustomerPrinterInfos = curPosInfo.receiptPrinterList.filter(
		(p) => p.hardwareType.usageType === 'NORMAL' && p.isActive
	);
	const CandidateCafePrinterInfos = curPosInfo.receiptPrinterList.filter(
		(p) => p.hardwareType.usageType === 'CAFE' && p.isActive
	);
	const CandidateKitchenPrinterInfos = curPosInfo.receiptPrinterList.filter(
		(p) => p.hardwareType.usageType === 'KITCHEN' && p.isActive
	);
	const CandidateReceiptDDIZIPrinterInfos = curPosInfo.receiptPrinterList.filter(
		(p) => p.hardwareType.usageType === 'DDIZI' && p.isActive
	);
	const handleClick금전함열기 = PrintReceiptState.useSendOpenBox({
		receiptPrinterList: curPosInfo.receiptPrinterList,
		printMode: curPosInfo.printMode,
	});

	const localSettings = PosElectronStoreState.usePosMainPageLocalSettings().data;
	const saveLocalSettingsMutation = PosElectronStoreState.useSavePosLocalSettings();

	const selectBarcodeModel = (selected: AvailableBarcodeModelType) => {
		if (localSettings)
			saveLocalSettingsMutation.mutate({
				...localSettings,
				usingBarcodeModel: selected,
			});
	};

	return (
		<Stack
			direction={'column'}
			flexGrow={1}>
			<SettingMenuItem
				title='영수증 프린터 포트'
				innerElement={
					<FormControl>
						<FormLabel id='rp-port'></FormLabel>
						<RadioGroup
							row
							name='rp-port'
							defaultValue='usb'>
							<FormControlLabel
								value='usb'
								control={<Radio />}
								label='USB'
							/>
							<FormControlLabel
								value='com1'
								control={<Radio />}
								label='COM1'
								disabled
							/>
							<FormControlLabel
								value='com2'
								control={<Radio />}
								label='COM2'
								disabled
							/>
						</RadioGroup>
					</FormControl>
				}
			/>
			<SettingMenuItem
				title='매표소 샘플 영수증'
				innerElement={
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={async () => {
								handleClick금전함열기();
							}}>
							현금함
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke고객영수증출력({
										...CustomerReceiptRequestDummy,
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
									});
								}
							}}>
							고객영수증
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke교환권출력({
										...ExchangeCouponRequestDummy,
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
									});
								}
							}}>
							교환권
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									printerInfo.hardwareType.usageType === 'NORMAL' &&
										(await ReactRPIPC.invoke시재점검표출력({
											...VaultcashReceiptRequestDummy,
											hardwareInfo: {
												deviceType: 'POS',
												printerInfo: printerInfo,
												printMode: printMode,
											},
										}));
								}
							}}>
							시재점검표
						</Button>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='카페 샘플 영수증'
				innerElement={
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCustomerPrinterInfos) {
									await ReactRPIPC.invoke고객영수증출력({
										...FoodCustomerReceiptRequestDummy,
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
									});
								}
							}}>
							고객영수증
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateCafePrinterInfos) {
									await ReactRPIPC.invoke프론트영수증출력({
										...FrontReceiptRequestDummy,
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
									});
								}
							}}>
							직원확인영수증
						</Button>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateKitchenPrinterInfos) {
									await ReactRPIPC.invoke주방영수증출력({
										...KitchenReceiptRequestDummy,
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
									});
								}
							}}>
							주방확인영수증
						</Button>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='영수증 프린터 티켓 출력'
				innerElement={
					<Stack
						direction={'row'}
						spacing={1}>
						<Button
							variant='outlined'
							onClick={async () => {
								for (const printerInfo of CandidateReceiptDDIZIPrinterInfos) {
									await ReactRPIPC.invoke영수증띠지출력({
										hardwareInfo: {
											deviceType: 'POS',
											printerInfo: printerInfo,
											printMode: printMode,
										},
										barcodeType: localSettings ? localSettings?.usingBarcodeModel : 'QRCODE',
										ddiziInfo: getDdiziDummy({ storeId, deviceId }),
									});
								}
							}}>
							영수증 티켓
						</Button>
					</Stack>
				}
			/>
			<SettingMenuItem
				title='바코드 모델 설정'
				innerElement={
					<Box>
						<Select
							sx={{ marginRight: 2, height: 45 }}
							defaultValue={localSettings?.usingBarcodeModel}
							onChange={(e) => {
								selectBarcodeModel(e.target.value as AvailableBarcodeModelType);
							}}>
							<MenuItem value='QRCODE'>모델 1 (QRCODE, 권장)</MenuItem>
							<MenuItem value='PDF417'>모델 2 (PDF417, 권장)</MenuItem>
							<MenuItem value='CODE128'>모델 3 (CODE128)</MenuItem>
							<MenuItem value='CODE93'>모델 4 (CODE93)</MenuItem>
							<MenuItem value='CODE39'>모델 5 (CODE39)</MenuItem>
							<MenuItem value='NONE'>미사용</MenuItem>
						</Select>
						<Button
							variant='outlined'
							onClick={async () => {
								console.log(`모델 ${localSettings?.usingBarcodeModel} 로 설정됨`);
							}}>
							설정
						</Button>
					</Box>
				}
			/>
			<SettingMenuItem
				title='기타 기능'
				innerElement={
					<Button
						sx={{ marginRight: 2 }}
						variant='outlined'
						disabled>
						프린터 상태
					</Button>
				}
			/>
		</Stack>
	);
};
