import { TeamOutlined } from '@ant-design/icons';
import {
	GuestVisitStatusTypeColor,
	GuestVisitStatusTypeLabel,
	GuestVisitType,
} from '@kinderlabs-pos/shared-data-type';
import {
	canceledStyleUtils,
	getTelephoneNumberWithDash,
	getTimeFormat,
} from '@kinderlabs-pos/shared-util';
import { Button, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';

export const GuestVisitDisplay = ({ guestVisit }: { guestVisit: GuestVisitType }) => {
	const { memberInfo, users, visitCount } = guestVisit;
	const totalVisitCount: number = useMemo(
		() => (users.length > 0 ? users.length : visitCount ? visitCount.totalCount : 0),
		[users, visitCount]
	);
	return (
		<Stack
			width={'100%'}
			direction={'row'}
			spacing={1}
			alignItems={'center'}>
			<Stack
				direction={'row'}
				flex={1}
				spacing={2}
				alignItems={'center'}>
				<Button
					variant={'outlined'}
					sx={{ px: 0.5, width: 80 }}
					color={GuestVisitStatusTypeColor[guestVisit.status]}>
					<Typography
						variant='body1'
						sx={{ ...canceledStyleUtils(guestVisit.status === 'CANCELED') }}>
						{GuestVisitStatusTypeLabel[guestVisit.status]}
					</Typography>
				</Button>
				<Stack>
					<Typography
						variant='h5'
						sx={{ ...canceledStyleUtils(guestVisit.status === 'CANCELED') }}>
						{`${memberInfo?.name ?? '이름없음'} (${
							memberInfo?.telephone
								? getTelephoneNumberWithDash(memberInfo?.telephone)
								: '지류 번호표'
						})`}
					</Typography>
					<Typography
						variant='subtitle2'
						sx={{ ...canceledStyleUtils(guestVisit.status === 'CANCELED') }}>
						{`순번 ${guestVisit.sunbun} (${getTimeFormat(guestVisit.createdDateTime)} 방문)`}
					</Typography>
				</Stack>
			</Stack>
			<Stack
				minWidth={120}
				alignItems={'flex-end'}>
				<Stack
					alignItems={'center'}
					direction={'row'}
					spacing={1}>
					<TeamOutlined style={{ fontSize: 15 }} />
					<Typography variant='body1'>{`전체 : ${totalVisitCount} 명`}</Typography>
				</Stack>
				<Stack
					direction={'row'}
					spacing={1}>
					{/* <UserOutlined style={{ fontSize: 15 }} />
					<Typography variant="body1">
						{`멤버십 ID : ${mainUser.memberInfo ? mainUser.memberInfo.id : '-'}`}
					</Typography> */}
				</Stack>
			</Stack>
		</Stack>
	);
};
