import { ReactPMIPC } from '@kinderlabs-pos/ipc/react';
import { VanCompanyType } from '@kinderlabs-pos/shared-data-type';

type HandlePaymentByPM = { amount: number; tax: number } & (
	| { method: 'CASH_IC' }
	| ({ method: 'CARD' | 'CARD_MS' } & { installment: number })
	| ({
			method: 'CASH_현금영수증_발급' | 'TRANSFER_현금영수증_발급' | 'GIFTCARD_현금영수증_발급';
	  } & {
			customerId: string;
			isEnterprise: boolean;
			isSignPadUsed: boolean;
	  })
	| ({
			method:
				| 'ZEROPAY'
				| 'SEOULPAY'
				| 'KAKAOPAY'
				| 'SSGPAY'
				| 'APPCARD'
				| 'ALIPAY'
				| 'WECHATPAY'
				| 'NAVERPAY'
				| 'BCPAYBOOC'
				| 'PAYCO';
	  } & { customerId: string })
);

export const useHandle결제모듈 = (vanCompany: VanCompanyType) => {
	return async (payload: HandlePaymentByPM) => {
		switch (payload.method) {
			case 'CARD':
				return await ReactPMIPC.invoke카드IC승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CARD_MS':
				return await ReactPMIPC.invoke카드MS승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CASH_IC':
				return await ReactPMIPC.invoke현금IC승인({
					...payload,
					vanCompany: vanCompany,
				});
			case 'CASH_현금영수증_발급':
			case 'GIFTCARD_현금영수증_발급':
			case 'TRANSFER_현금영수증_발급':
				// 현금 영수증 발급하며 결제
				return await ReactPMIPC.invoke현금영수증승인({
					...payload,
					vanCompany: vanCompany,
				});
			// case 'KAKAOPAY':
			// case 'SSGPAY':
			// case 'APPCARD':
			// 	return await ReactPMIPC.invoke간편결제승인({
			// 		...payload,
			// 		vanCompany: vanCompany,
			// 	});
			// case 'ZEROPAY':
			// case 'SEOULPAY':
			// 	return await ReactPMIPC.invoke간편결제승인({
			// 		...payload,
			// 		installment: 0,
			// 		vanCompany: vanCompany,
			// 	});
			case 'KAKAOPAY':
				return await ReactPMIPC.invoke카카오페이승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'SSGPAY':
				return await ReactPMIPC.invoke쓱페이승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'APPCARD':
				return await ReactPMIPC.invoke앱카드승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'ALIPAY':
				return await ReactPMIPC.invoke알리페이승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'WECHATPAY':
				return await ReactPMIPC.invoke위챗페이승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'NAVERPAY':
				return await ReactPMIPC.invoke네이버페이승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'BCPAYBOOC':
				return await ReactPMIPC.invoke비씨페이북승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'PAYCO':
				return await ReactPMIPC.invoke페이코승인({
					...payload,
					vanCompany: vanCompany,
					installment: 0,
				});
			case 'SEOULPAY':
				return await ReactPMIPC.invoke서울페이승인({
					...payload,
					installment: 0,
					vanCompany: vanCompany,
				});
			case 'ZEROPAY':
				return await ReactPMIPC.invoke제로페이승인({
					...payload,
					installment: 0,
					vanCompany: vanCompany,
				});

			default:
				throw Error('[TypeError] (PM) 결제 모듈 타입이 없습니다.');
		}
	};
};
