import { ipcPMApiName } from '@kinderlabs-pos/ipc/common';
import {
	VanCompanyType,
	VanCompanyType_현금영수증단말기발급시Number입력모드사용분리,
	getBinDummy,
	getEmptyDummy,
} from '@kinderlabs-pos/shared-data-type';

import {
	invoke간편결제승인Type,
	invoke간편결제취소Type,
	invoke결제강제취소Type,
	invoke서명패드숫자입력요청Type,
	invoke수표확인요청Type,
	invoke카드BIN요청Type,
	invoke카드IC승인Type,
	invoke카드IC취소Type,
	invoke카드MS승인Type,
	invoke카드MS취소Type,
	invoke카드삽입여부확인요청Type,
	invoke현금IC승인type,
	invoke현금IC취소Type,
	invoke현금영수증승인Type,
	invoke현금영수증취소Type,
} from './types/ReactHardwareIPCTypes';
import { WebposError, isOnElectron } from './const';

const invoke현금영수증승인: invoke현금영수증승인Type = async (args) => {
	if (!isOnElectron) throw WebposError();

	// 24.05.30
	// JIN: 로직 상세 설명은 'VanCompanyType_현금영수증단말기발급시Number입력모드사용분리' 의 주석을 참고하세요
	if (
		args.isSignPadUsed &&
		VanCompanyType_현금영수증단말기발급시Number입력모드사용분리[args.vanCompany]
	) {
		const customerId = (await invoke서명패드숫자입력요청({ vanCompany: args.vanCompany }))
			.responseMessage;

		const response = await window[ipcPMApiName].invoke결제모듈요청({
			key: 'payment-request',
			args: {
				...args,
				requestMethod: 'APPROVAL',
				requestChannel: 'CASH_RECEIPT',
				// RESPONSE 에 Customer Id 를 붙여줍니다.
				customerId,
			},
		});

		return {
			...response,
			customerId,
		};
	} else {
		const response = await window[ipcPMApiName].invoke결제모듈요청({
			key: 'payment-request',
			args: {
				...args,
				requestMethod: 'APPROVAL',
				requestChannel: args.isSignPadUsed ? 'CASH_RECEIPT_SIGNPAD' : 'CASH_RECEIPT',
			},
		});

		return {
			...response,
			// RESPONSE 에 Customer Id 를 붙여줍니다.
			customerId: args.customerId,
		};
	}
};

// : Promise.reject({ message: '실패' });
const invoke현금영수증취소: invoke현금영수증취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: args.isSignPadUsed ? 'CASH_RECEIPT_SIGNPAD' : 'CASH_RECEIPT',
		},
	});
};

const invoke현금IC승인: invoke현금IC승인type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'CASH_IC',
		},
	});
};

const invoke현금IC취소: invoke현금IC취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'CASH_IC',
		},
	});
};

const invoke카드IC승인: invoke카드IC승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'CARD_IC',
		},
	});
};

const invoke카드IC취소: invoke카드IC취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'CARD_IC',
		},
	});
};

const invoke카드MS승인: invoke카드MS승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'CARD_MS',
		},
	});
};

const invoke카드MS취소: invoke카드MS취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'CARD_MS',
		},
	});
};

const invoke카드삽입여부확인요청: invoke카드삽입여부확인요청Type = (args: {
	vanCompany: VanCompanyType;
}) =>
	isOnElectron
		? window[ipcPMApiName].invoke결제모듈요청({
				key: 'payment-request',
				args: {
					requestMethod: 'REQ_CARD_INSERTED',
					requestChannel: 'ANY_TYPE',
					vanCompany: args.vanCompany,
				},
		  })
		: Promise.resolve(getBinDummy());

// const invoke간편결제승인: invoke간편결제승인Type = (args) => {
// 	if (!isOnElectron) throw WebposError();
// 	return window[ipcPMApiName].invoke결제모듈요청({
// 		key: 'payment-request',
// 		args: {
// 			...args,
// 			requestMethod: 'APPROVAL',
// 			requestChannel: 'EASYPAY',
// 		},
// 	});
// };

// const invoke간편결제취소: invoke간편결제취소Type = (args) => {
// 	if (!isOnElectron) throw WebposError();
// 	return window[ipcPMApiName].invoke결제모듈요청({
// 		key: 'payment-request',
// 		args: {
// 			...args,
// 			requestMethod: 'CANCEL',
// 			requestChannel: 'EASYPAY',
// 		},
// 	});
// };

const invoke카카오페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'KAKAOPAY',
		},
	});
};

const invoke카카오페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'KAKAOPAY',
		},
	});
};
const invoke쓱페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'SSGPAY',
		},
	});
};

const invoke쓱페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'SSGPAY',
		},
	});
};

const invoke서울페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'SEOULPAY',
		},
	});
};

const invoke서울페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'SEOULPAY',
		},
	});
};

const invoke앱카드승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'APPCARD',
		},
	});
};

const invoke앱카드취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'APPCARD',
		},
	});
};

const invoke알리페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'ALIPAY',
		},
	});
};

const invoke알리페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'ALIPAY',
		},
	});
};
const invoke위챗페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'WECHATPAY',
		},
	});
};

const invoke위챗페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'WECHATPAY',
		},
	});
};
const invoke네이버페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'NAVERPAY',
		},
	});
};

const invoke네이버페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'NAVERPAY',
		},
	});
};
const invoke비씨페이북승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'BCPAYBOOC',
		},
	});
};

const invoke비씨페이북취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'BCPAYBOOC',
		},
	});
};
const invoke제로페이승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'ZEROPAY',
		},
	});
};

const invoke제로페이취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'ZEROPAY',
		},
	});
};
const invoke페이코승인: invoke간편결제승인Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'APPROVAL',
			requestChannel: 'PAYCO',
		},
	});
};

const invoke페이코취소: invoke간편결제취소Type = (args) => {
	if (!isOnElectron) throw WebposError();
	return window[ipcPMApiName].invoke결제모듈요청({
		key: 'payment-request',
		args: {
			...args,
			requestMethod: 'CANCEL',
			requestChannel: 'PAYCO',
		},
	});
};

const invoke결제강제취소: invoke결제강제취소Type = async (args: { vanCompany: VanCompanyType }) => {
	try {
		if (isOnElectron)
			return await window[ipcPMApiName].invoke결제모듈요청({
				key: 'payment-request',
				args: {
					requestMethod: 'FORCED_CANCEL',
					requestChannel: 'ANY_TYPE',
					vanCompany: args.vanCompany,
				},
			});
		else return getEmptyDummy();
	} catch (e) {
		console.log(e);
		// 아무 쓸모없는 코드
		return getEmptyDummy();
	}
};

const invoke카드BIN요청: invoke카드BIN요청Type = (args: { vanCompany: VanCompanyType }) =>
	isOnElectron
		? window[ipcPMApiName].invoke결제모듈요청({
				key: 'payment-request',
				args: {
					requestMethod: 'REQ_CARD_BIN',
					requestChannel: 'ANY_TYPE',
					vanCompany: args.vanCompany,
				},
		  })
		: Promise.resolve(getBinDummy());

const invoke수표확인요청: invoke수표확인요청Type = (args) =>
	isOnElectron
		? window[ipcPMApiName].invoke결제모듈요청({
				key: 'payment-request',
				args: {
					requestMethod: 'REQ_CHECK_VALIDITY',
					requestChannel: 'ANY_TYPE',
					...args,
				},
		  })
		: Promise.resolve(getBinDummy());

const invoke서명패드숫자입력요청: invoke서명패드숫자입력요청Type = (args: {
	vanCompany: VanCompanyType;
}) =>
	isOnElectron
		? window[ipcPMApiName].invoke결제모듈요청({
				key: 'payment-request',
				args: {
					requestMethod: 'REQ_SIGNPAD_NUM_INPUT',
					requestChannel: 'ANY_TYPE',
					vanCompany: args.vanCompany,
				},
		  })
		: Promise.resolve(getBinDummy());

export const ReactPMIPC = {
	invoke현금영수증승인,
	invoke현금영수증취소,
	invoke카드삽입여부확인요청,
	invoke현금IC승인,
	invoke현금IC취소,
	invoke카드IC승인,
	invoke카드IC취소,
	invoke카드MS승인,
	invoke카드MS취소,
	invoke결제강제취소,
	invoke카드BIN요청,

	// 간편결제류
	// invoke간편결제승인,
	// invoke간편결제취소,
	invoke카카오페이승인,
	invoke카카오페이취소,
	invoke쓱페이승인,
	invoke쓱페이취소,
	invoke서울페이승인,
	invoke서울페이취소,
	invoke앱카드승인,
	invoke앱카드취소,
	invoke알리페이승인,
	invoke알리페이취소,
	invoke위챗페이승인,
	invoke위챗페이취소,
	invoke네이버페이승인,
	invoke네이버페이취소,
	invoke비씨페이북승인,
	invoke비씨페이북취소,
	invoke제로페이승인,
	invoke제로페이취소,
	invoke페이코승인,
	invoke페이코취소,
	invoke수표확인요청,
	invoke서명패드숫자입력요청,
};
