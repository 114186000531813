import { DeviceType } from '../ConnectInfoType';
import { hardwareInfoType } from '../HardwareDataType/ReceiptPrinterType';
import { BaseInfoType } from './BaseInfoType';
import { SalesDeviceInfoType } from './DeviceInfoType';

export const AdminPosTypeArray = ['MAIN', 'KITCHEN', 'WEBPOS', 'GOODS'] as const;
export type PosType = (typeof AdminPosTypeArray)[number];
export const PosTypeLabel: Record<PosType, string> = {
	KITCHEN: '주방',
	MAIN: '매표소',
	WEBPOS: '웹포스',
	GOODS: '상품샵',
};

export const PrintModeTypeArray = ['ESCPOS', 'BIXOLONSDK'] as const;
export type PrintModeType = (typeof PrintModeTypeArray)[number];
export const PrintModeTypeLabel: Record<PrintModeType, string> = {
	ESCPOS: '직접연결',
	BIXOLONSDK: '빅솔론 연결',
};

export const VanCompanyTypeArray = [
	'NICE',
	'NICE_PAYMENTS',
	'KICC',
	'KSNET',
	'SMARTRO',
	'KOVAN',
	'KCP',
	'KIS',
	'NO_PAYMENT',
] as const;
export type VanCompanyType = (typeof VanCompanyTypeArray)[number];
export const VanComponayTypeLabel: Record<VanCompanyType, string> = {
	NICE: 'NICE',
	NICE_PAYMENTS: 'NICE PAYMENETS',
	KICC: 'KICC',
	KSNET: 'KSNET',
	SMARTRO: 'SMARTRO',
	KOVAN: 'KOVAN',
	KCP: 'KCP',
	KIS: 'KIS',
	NO_PAYMENT: '없음',
};
// NICE, NICE_PAYMENTS, KICC 이외의 결제모듈은 확인필요
export const VanCompanyTypeCashReceiptInputSeperated: Record<VanCompanyType, boolean> = {
	NICE: true,
	NICE_PAYMENTS: true,
	KICC: false,
	KSNET: false,
	SMARTRO: false,
	KOVAN: false,
	KCP: false,
	KIS: true,
	NO_PAYMENT: false,
};

/**
 * JIN
 * 현금영수증 발급시에는 010 1234 1234 와 같은 전화번호를 받는 방법이 2가지가 있습니다.
 * 1. 현금영수증 발급시 단말기에 010 1234 1234 를 화면에서 받아서 넣어줌
 * 2. 현금영수증 발급시 단말기에 숫자를 직접 입력받게끔 함
 *
 * 1번 기능은 isSignPadUsed: false 2번 기능은 isSignPadUsed: true 입니다.
 *
 * 다만 2번 기능은 개인정보보호법에 따라 전화번호를 010 **** 1234 로 받게 받지 못하여 영수증 취소시에 전화번호를 다시 입력받아야 합니다.
 *
 * 그래서 일부 결제단말기사에서는 단말기에 전화번호 입력 API 가 따로 있습니다.
 * 해당 값이 True 인 결제단말기사에서는 2번 기능 사용시 (isSignPadUsed: true) 일시,
 * 단말기에 전화번호 입력 API 를 호출 -> 온전히 받은 전화번호를 1번 기능을 이용해서 전화번호 입력과 같은 방식으로 처리합니다.
 * 이러면 전화번호에 **** 로 마스킹되지 않은 온전한 값을 받아서 영수증 취소에 활용 가능합니다.
 */
export const VanCompanyType_현금영수증단말기발급시Number입력모드사용분리: Record<
	VanCompanyType,
	boolean
> = {
	NICE: false,
	NICE_PAYMENTS: false,
	KICC: true,
	KSNET: false,
	SMARTRO: false,
	KOVAN: false,
	KCP: false,
	KIS: false,
	NO_PAYMENT: false,
};

export const 수표검증가능: Record<VanCompanyType, boolean> = {
	NICE: false,
	NICE_PAYMENTS: false,
	KICC: true,
	KSNET: false,
	SMARTRO: false,
	KOVAN: false,
	KCP: false,
	KIS: false,
	NO_PAYMENT: false,
};

export type PosInfoType = SalesDeviceInfoType & {
	deviceType: 'POS';
	parkingUrl?: string;
	posFileUrl?: string;
	kitchenSystemId?: number;
	receiptAutoPrint: boolean;
	barcodeAlert: boolean;
	printMode: PrintModeType;
	vanCompany: VanCompanyType;
	posType: PosType;
	labelPrinterList: PrinterInfoType[];
	receiptPrinterList: PrinterInfoType[];
};

export const PosBoardDeviecTypeArray = ['POS', 'ADMIN'] as const;
export type PosBoardDeviceType = (typeof PosBoardDeviecTypeArray)[number];

export const PosPinterHardWareTypeArray = ['LABEL_PRINTER', 'RECEIPT_PRINTER'] as const;
export type PosPrinterHardWareType = (typeof PosPinterHardWareTypeArray)[number];
export const PosPrinterHardWareLabel: Record<PosPrinterHardWareType, string> = {
	LABEL_PRINTER: '라벨 프린터',
	RECEIPT_PRINTER: '영수증 프린터',
};

export const PosLabelPrinterDeviceTypeArray = ['MAIN', 'SUB'] as const;
export type PosLabelPrinterDeviceType = (typeof PosLabelPrinterDeviceTypeArray)[number];
export const PosLabelPrinterLabel: Record<PosLabelPrinterDeviceType, string> = {
	MAIN: '메인 프린터',
	SUB: '서브 프린터',
};

export const PosReceiptPrinterTypeArray = ['NORMAL', 'CAFE', 'KITCHEN'] as const;
export type PosReceiptPrinterType = (typeof PosReceiptPrinterTypeArray)[number];
export const PosReceiptPrinterLabel: Record<PosReceiptPrinterType, string> = {
	NORMAL: '일반',
	CAFE: '카페',
	KITCHEN: '주방',
};

export type PrinterInfoType = {
	id?: number;
	deviceId: number;
	deviceType: DeviceType;
	logicalName: string;
	modelName: string;
	hardwareType: {
		mainType: PosPrinterHardWareMainType;
		usageType: PosLabelPrinterUsageType | PosReceiptPrinterUsageType;
	};
	quantity: number;
	isActive: boolean;
	connectionType: ConnectionType;
	regex?: string;
};

/**
 * @author JIN
 * - ESCPOS 의 고유값
 *   `printerInfo.connectionType.mode`
 *       - USB : printerInfo.modelName
 *       - NETWORK : printerInfo.connectionType.ip, printerInfo.*connectionType.networkPort
 *       - SERIAL : printerInfo.connectionType.serialPort
 * - BIXOLON 의 고유값
 *   - hardwareInfo.printerInfo.logicalName
 */
export const getPrinterQueueName = (hardwareInfo?: hardwareInfoType) => {
	if (!hardwareInfo) return 'default_queue';

	const { printMode, printerInfo } = hardwareInfo;
	if (printMode === 'BIXOLONSDK') return printerInfo.logicalName;
	else if (printMode === 'ESCPOS')
		switch (printerInfo.connectionType.mode) {
			case 'SERIAL':
				return printerInfo.connectionType.serialPort ?? 'default_queue';
			case 'USB':
				return printerInfo.modelName ?? 'default_queue';
			case 'NETWORK':
				return printerInfo.connectionType.ip && printerInfo.connectionType.networkPort
					? `${printerInfo.connectionType.ip}_${printerInfo.connectionType.networkPort}`
					: 'default_queue';
			default:
				return 'default_queue';
		}
	else return 'default_queue';
};

export const PosPinterHardWareMainTypeArray = ['LABEL_PRINTER', 'RECEIPT_PRINTER'] as const;
export type PosPrinterHardWareMainType = (typeof PosPinterHardWareMainTypeArray)[number];
export const PosPrinterHardWareMainLabel: Record<PosPrinterHardWareMainType, string> = {
	LABEL_PRINTER: '라벨 프린터',
	RECEIPT_PRINTER: '영수증 프린터',
};

export const PosLabelPrinterUsageTypeArray = ['NORMAL', 'PARENT'] as const;
export type PosLabelPrinterUsageType = (typeof PosLabelPrinterUsageTypeArray)[number];
export const PosLabelPrinterUsageLabel: Record<PosLabelPrinterUsageType, string> = {
	NORMAL: '메인',
	PARENT: '서브',
};

export const PosReceiptPrinterUsageTypeArray = ['NORMAL', 'CAFE', 'KITCHEN', 'DDIZI'] as const;
export type PosReceiptPrinterUsageType = (typeof PosReceiptPrinterUsageTypeArray)[number];
export const PosReceiptPrinterUsageLabel: Record<PosReceiptPrinterUsageType, string> = {
	NORMAL: '일반',
	CAFE: '카페',
	KITCHEN: '주방',
	DDIZI: '영수증 티켓',
};

export const ConnectionModeTypeArray = ['NETWORK', 'SERIAL', 'USB'] as const;
export type ConnectionModeType = (typeof ConnectionModeTypeArray)[number];
export const ConnectionModeTypeLabel: Record<ConnectionModeType, string> = {
	SERIAL: '시리얼',
	USB: 'USB',
	NETWORK: '네트워크',
};

export const SerialConnectionParityTypeArray = ['NONE', 'EVEN', 'ODD'] as const;
export type SerialConnectionParityType = (typeof SerialConnectionParityTypeArray)[number];
export const SerialConnetcionParityLabel: Record<SerialConnectionParityType, string> = {
	NONE: '없음',
	EVEN: '듀얼',
	ODD: '모노',
};

export type ConnectionType =
	| (BaseInfoType & { id: number } & {
			mode: 'SERIAL';
			serialPort?: string;
			baudRate?: number;
			dataBits?: number;
			stopBits?: number;
			parity?: SerialConnectionParityType;
	  })
	| (BaseInfoType & { id: number } & {
			mode: 'USB';
			vid?: string;
			pid?: string;
			alias?: number;
	  })
	| (BaseInfoType & { id: number } & {
			mode: 'NETWORK';
			ip?: string;
			networkPort?: number;
	  });

export const 포스통계출력선택TypeArray = ['PRODUCT', 'CARD', 'PAYMENT', 'SUMMARY'] as const;
export type 포스통계출력선택Type = (typeof 포스통계출력선택TypeArray)[number];
export const 포스통계출력선택TypeLabel: Record<포스통계출력선택Type, string> = {
	SUMMARY: '결제 요약',
	CARD: '카드사별 매출',
	PAYMENT: '결제수단별 매출',
	PRODUCT: '상품별 매출',
};
