import { DayCalendarFilterOptionType } from '../CalendarFilterOptionType';
import { CardPaymentType } from '../HardwareDataType/PaymentModuleType';
import { OrderType } from '../Order/OrderType';
import { Pageable } from '../PageType';
import { ReceiptInfoType } from './ReceiptInfoType';
import { GiftCardProviderType } from './GiftCardType';
import { VanCompanyType } from '../admin/PosInfoType';

export type PaymentStatusType = 'COMPLETE' | 'REFUND';
export type AdminPaymentFilterType = {
	storeIdList?: number[];
	posIdList?: number[];
	page: number;
	size?: number;
	method?: PaymentMethodType;
	status?: PaymentStatusType;
	cardPaymentType?: CardPaymentType;
	// ddiziInfoId?: number;
	search?: string;
	calendarOption: DayCalendarFilterOptionType;
	sort?: Pageable['sort'];
};

export const EasyPayMethodTypeArray = [
	'KAKAOPAY',
	'SSGPAY',
	'SEOULPAY',
	'APPCARD',
	'ALIPAY',
	'WECHATPAY',
	'NAVERPAY',
	'BCPAYBOOC',
	'ZEROPAY',
	'PAYCO',
] as const;
export type EasyPayMethodType = (typeof EasyPayMethodTypeArray)[number];

export const PaymentMethodTypeArray = [
	...EasyPayMethodTypeArray,
	'CARD',
	'CASH',
	'VIRTUAL_CARD',
	'TRANSFER',
	'ZEROPAY_MPM',
	'SEOULPAY_MPM',
	'CASH_IC',
	'CARD_MS',
	'CREDIT',
	'POINT',
	'GIFTCARD',
] as const;
export type PaymentMethodType = (typeof PaymentMethodTypeArray)[number];
export const PaymentMethodTypeLabel: Record<PaymentMethodType, string> = {
	CARD: 'IC카드',
	CASH: '현금',
	VIRTUAL_CARD: '가상카드',
	TRANSFER: '계좌이체',
	ZEROPAY_MPM: '제로페이(MPM)',
	SEOULPAY_MPM: '서울페이(MPM)',
	CASH_IC: '현금IC카드', // 할부없음
	CARD_MS: 'MS카드', // 할부있음
	CREDIT: '외상',
	POINT: '포인트',
	GIFTCARD: '상품권',
	KAKAOPAY: '카카오페이',
	SSGPAY: 'SSG페이',
	SEOULPAY: '서울페이',
	APPCARD: '앱카드',
	ALIPAY: '알리페이',
	WECHATPAY: '위챗페이',
	NAVERPAY: '네이버페이',
	BCPAYBOOC: '페이북',
	ZEROPAY: '제로페이',
	PAYCO: '페이코',
};

export const is할부가능: Record<PaymentMethodType, boolean> = {
	CARD: true,
	CASH: false,
	VIRTUAL_CARD: true,
	TRANSFER: false,
	ZEROPAY_MPM: false,
	SEOULPAY_MPM: false,
	CASH_IC: false,
	CARD_MS: true,
	CREDIT: false,
	POINT: false,
	GIFTCARD: false,
	KAKAOPAY: true,
	SSGPAY: true,
	SEOULPAY: false,
	APPCARD: true,
	ALIPAY: true,
	WECHATPAY: true,
	NAVERPAY: true,
	BCPAYBOOC: true,
	ZEROPAY: false,
	PAYCO: true,
};

export const need메모옵션: Record<PaymentMethodType, '없음' | '가능' | '필수'> = {
	CARD: '없음',
	CASH: '없음',
	VIRTUAL_CARD: '가능',
	TRANSFER: '필수',
	ZEROPAY_MPM: '없음',
	SEOULPAY_MPM: '없음',
	CASH_IC: '없음',
	CARD_MS: '없음',
	CREDIT: '필수',
	POINT: '없음',
	GIFTCARD: '없음',
	KAKAOPAY: '없음',
	SSGPAY: '없음',
	SEOULPAY: '없음',
	APPCARD: '없음',
	ALIPAY: '없음',
	WECHATPAY: '없음',
	NAVERPAY: '없음',
	BCPAYBOOC: '없음',
	ZEROPAY: '없음',
	PAYCO: '없음',
};

// 이게 ON 이면 공식 발급된 영수증이 없는 결제 형태일수도 있다는 말이다.
export const has비공식영수증: Record<PaymentMethodType, boolean> = {
	CARD: false,
	CASH: true,
	VIRTUAL_CARD: true, // 2023.11.03 가상카드에서 영수증 제거.
	TRANSFER: true,
	ZEROPAY_MPM: true,
	SEOULPAY_MPM: true,
	CASH_IC: false,
	CARD_MS: false,
	CREDIT: true,
	POINT: true,
	GIFTCARD: true,
	KAKAOPAY: false,
	SSGPAY: false,
	SEOULPAY: false,
	APPCARD: false,
	ALIPAY: false,
	WECHATPAY: false,
	NAVERPAY: false,
	BCPAYBOOC: false,
	ZEROPAY: false,
	PAYCO: false,
};

/* 애매해서 주석처리함 has공식영수증은 아래 
  export interface CardMSPaymentInfoType extends PaymentRequestInfoType {
    method: 'CARD_MS';
    receipt: ReceiptInfoType;
  }
  부분에서 receipt 가 필수나 있냐 없냐로 표현됨.
  예를 들어 SEOULPAY_MPM 는 receipt 가 존재하지 않음.
*/
// export const has공식영수증: Record<PaymentMethodType, boolean> = {
// 	CARD: true,
// 	CASH: true,
// 	VIRTUAL_CARD: true,
// 	TRANSFER: true,
// 	KAKAOPAY: true,
// 	SSGPAY: true,
// 	ZEROPAY: true,
// 	ZEROPAY_MPM: false,
// 	SEOULPAY: true,
// 	SEOULPAY_MPM: false,
// 	CASH_IC: true,
// 	CARD_MS: true,
// 	CREDIT: false,
// 	APPCARD: true,
// 	POINT: false,
// };

export const has공식현금영수증: Record<PaymentMethodType, boolean> = {
	CARD: false,
	CASH: true,
	VIRTUAL_CARD: false,
	TRANSFER: true,
	ZEROPAY_MPM: false,
	SEOULPAY_MPM: false,
	CASH_IC: false,
	CARD_MS: false,
	CREDIT: false,
	POINT: false,
	GIFTCARD: true,
	KAKAOPAY: false,
	SSGPAY: false,
	SEOULPAY: false,
	APPCARD: false,
	ALIPAY: false,
	WECHATPAY: false,
	NAVERPAY: false,
	BCPAYBOOC: false,
	ZEROPAY: false,
	PAYCO: false,
};

export const has간편결제미지원: Record<EasyPayMethodType, boolean> = {
	KAKAOPAY: false,
	SSGPAY: false,
	SEOULPAY: false,
	APPCARD: false,
	ALIPAY: false,
	WECHATPAY: false,
	NAVERPAY: false,
	BCPAYBOOC: false,
	ZEROPAY: false,
	PAYCO: false,
};

// 찐으로 지원하는 타입. 하지만 몇개만 열고 나중에 추가적으로 엽시다
// export const has간편결제지원KICC: Record<EasyPayMethodType, boolean> = {
// 	KAKAOPAY: true,
// 	SSGPAY: true,
// 	SEOULPAY: true,
// 	APPCARD: true,
// 	ALIPAY: true,
// 	WECHATPAY: true,
// 	NAVERPAY: true,
// 	BCPAYBOOC: true,
// 	ZEROPAY: true,
// 	PAYCO: false,
// };

// 1차 지원타입.
// 카카오페이 / 서울페이 / 앱카드 -> 먼저써보자
export const has간편결제지원KICC: Record<EasyPayMethodType, boolean> = {
	KAKAOPAY: true,
	SSGPAY: false,
	SEOULPAY: true,
	APPCARD: true,
	ALIPAY: false,
	WECHATPAY: false,
	NAVERPAY: false,
	BCPAYBOOC: false,
	ZEROPAY: false,
	PAYCO: false,
};

export const has간편결제지원: Record<VanCompanyType, Record<EasyPayMethodType, boolean>> = {
	NICE: has간편결제미지원,
	NICE_PAYMENTS: has간편결제미지원,
	KICC: has간편결제지원KICC,
	KSNET: has간편결제미지원,
	SMARTRO: has간편결제미지원,
	KOVAN: has간편결제미지원,
	KCP: has간편결제미지원,
	KIS: has간편결제미지원,
	NO_PAYMENT: has간편결제미지원,
};

export interface PaymentInfoType {
	id: string;
	created: Date;
	amount: number;
	taxFreeAmount: number;
	tax: number;
	storeId: number;
	posId: number;
	orderId: OrderType['id'];
	// jungSanId?: number;
	method: PaymentMethodType;
	receipt?: ReceiptInfoType;
	refundReceipt?: ReceiptInfoType;
	refunded?: Date;
	memberId?: number;
	memo?: string;
}

export type PaymentTableDataType = Omit<
	PaymentInfoType,
	'storeId' | 'status' | 'amount' | 'created'
> & {
	storeId: string;
	status: string;
	amount: string;
	created: string;
};

export type PaymentRequestInfoType = Omit<PaymentInfoType, 'id' | 'orderId'> & {
	id?: string;
	orderId?: string;
};

/**
 * refundId 는 현금 취소는 undefined 가 되고 카드 취소 등은 bin 이 된다.
 * refunded 는 환불 시각을 의미한다.
 */
export interface RefundInfoType {
	refundId: string;
	refunded: Date;
	receiptId?: string;
}

export interface CardPaymentInfoType extends PaymentRequestInfoType {
	method: 'CARD';
	receipt: ReceiptInfoType;
}

export interface CardMSPaymentInfoType extends PaymentRequestInfoType {
	method: 'CARD_MS';
	receipt: ReceiptInfoType;
}

export interface CashICPaymentInfoType extends PaymentRequestInfoType {
	method: 'CASH_IC';
	receipt: ReceiptInfoType;
}

// 가상카드 결제
export interface VirtualCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'VIRTUAL_CARD';
	memo?: string;
	receipt: undefined;
}

export interface CashPaymentInfoType extends PaymentRequestInfoType {
	method: 'CASH';
	receivedMoney?: number;
	// 현금 영수증을 함께하는 경우
	receipt?: ReceiptInfoType;
}

// 계좌이체
export interface TransferPaymentInfoType extends PaymentRequestInfoType {
	method: 'TRANSFER';
	memo: string;
	receipt?: ReceiptInfoType;
}

// 외상결제
export interface CreditPaymentInfoType extends PaymentRequestInfoType {
	method: 'CREDIT';
	memo: string;
	receipt: undefined;
}

export interface ZeroPayMPMPaymentInfoType extends PaymentRequestInfoType {
	method: 'ZEROPAY_MPM';
	receipt: undefined;
}

export interface SeoulPayMPMPaymentInfoType extends PaymentRequestInfoType {
	method: 'SEOULPAY_MPM';
	receipt: undefined;
}

// 포인트사용
export interface PointUseInfoType extends PaymentRequestInfoType {
	method: 'POINT';
	memberId: number;
	receipt: undefined;
}

export interface GiftCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'GIFTCARD';
	giftCardNumber: string;
	giftCardName: string;
	giftCardProvider: GiftCardProviderType;
}

// 간편결제들
export interface KakaoPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'KAKAOPAY';
	receipt: ReceiptInfoType;
}

export interface SSGPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'SSGPAY';
	receipt: ReceiptInfoType;
}

export interface SeoulPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'SEOULPAY';
	receipt: ReceiptInfoType;
}

export interface APPCardPaymentInfoType extends PaymentRequestInfoType {
	method: 'APPCARD';
	receipt: ReceiptInfoType;
}

export interface AliPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'ALIPAY';
	receipt: ReceiptInfoType;
}
export interface WechatPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'WECHATPAY';
	receipt: ReceiptInfoType;
}
export interface NaverPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'NAVERPAY';
	receipt: ReceiptInfoType;
}
export interface BCPAYBOOCPaymentInfoType extends PaymentRequestInfoType {
	method: 'BCPAYBOOC';
	receipt: ReceiptInfoType;
}

export interface ZeroPayPaymentInfoType extends PaymentRequestInfoType {
	method: 'ZEROPAY';
	receipt: ReceiptInfoType;
}

export interface PaycoPaymentInfoType extends PaymentRequestInfoType {
	method: 'PAYCO';
	receipt: ReceiptInfoType;
}

export type 현금영수증OptionType =
	| { type: '소득공제'; customerId: string; isSignPadUsed: boolean }
	| { type: '지출증빙'; customerId: string; isSignPadUsed: boolean };
